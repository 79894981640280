import $axios from '@/router/server'

const Task = {
  // START Task API
  getTask ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const { page, itemsPerPage, sortBy, sortDesc } = payload
      let query = {
        page: page || 1,
        per_page: itemsPerPage || 5,
        sortBy: sortBy.length ? JSON.stringify(sortBy) : '',
        sortDesc: sortDesc.length ? JSON.stringify(sortDesc) : '',
        ...payload.add
      }
      query = new URLSearchParams(query).toString()
      $axios.get(`/task/all?${query}`)
        .then((response) => {
          if (response.status === 200) {
            const items = response.data.value.data
            const total = response.data.value.total
            resolve({ items, total })
          } else {
            resolve({ items: [], total: 0 })
          }
        })
        .catch((error) => {
          console.log(error)
          resolve({ items: [], total: 0 })
        })
    })
  },
  getTaskById ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      $axios.get(`/task/detail/${payload.id}`)
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data.value)
          } else {
            resolve(response.data.value)
          }
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  createTask ({ commit }) {
    return new Promise((resolve, reject) => {
      $axios.get('/task/create')
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data.value)
          } else {
            resolve(response.data.value)
          }
        })
        .catch((error) => {
          console.log(error)
          resolve([])
        })
    })
  },
  addTask ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      $axios.post('/task/baru', payload, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data)
          } else {
            resolve({ msg: 'Sepertinya ada masalah, silahkan coba lagi' })
          }
        })
        .catch((error) => {
          console.log(error)
          resolve({ msg: error })
        })
    })
  },
  editTask ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      $axios.get(`/task/edit/${payload.id}`)
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data.value)
          } else {
            resolve(response.data.value)
          }
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  updateTask ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      $axios.post('/task/update', payload, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data)
          }
          resolve({ msg: 'Sepertinya ada masalah, silahkan coba lagi' })
        })
        .catch((error) => {
          console.log(error)
          resolve({ msg: error })
        })
    })
  },
  deleteTask ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      $axios.delete(`/task/delete/${payload}`)
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data)
          }
          resolve({ msg: 'Sepertinya ada masalah, silahkan coba lagi' })
        })
        .catch((error) => {
          console.log(error)
          resolve({ msg: error })
        })
    })
  },
  getSupport ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      $axios.get('/task/support')
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data.value)
          } else {
            resolve([])
          }
        })
        .catch((error) => {
          console.log(error)
          resolve([])
        })
    })
  },
  getDash ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      $axios.get('/task/dash')
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data.value)
          } else {
            resolve([])
          }
        })
        .catch((error) => {
          console.log(error)
          resolve([])
        })
    })
  },
  getTaskReport ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      $axios.post('/task/report', payload, { responseType: 'blob' })
        .then((response) => {
          if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', response.headers.pragma)
            document.body.appendChild(link)
            link.click()
            resolve(true)
          } else {
            resolve(false)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  // END Task API
}

export default Task
